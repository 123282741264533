<template>
  <div>
    <van-skeleton v-if="loading.content" title :row="3" class="mt-4" />
    <div v-if="!error_state && !loading.content && !page.success_survey">
      <!-- <van-image fit="contain" :src="banner[0]" class="p-2 no-border" /> -->
      <van-swipe indicator-color="#FB7418" :autoplay="3000">
        <van-swipe-item v-for="(image, index) in banner" :key="index">
          <van-image fit="contain" :src="image" class="p-2 no-border" />
        </van-swipe-item>
      </van-swipe>
      <div class="van-ellipsis--l2 p-2 m-2" style="white-space: pre-wrap;" v-html="description">
      </div>
      <van-steps :active="step" v-if="campaign.per_page">
        <van-step v-for="(index) in total_page" :key="index">
          Langkah {{index}}
        </van-step>
      </van-steps>
      <van-cell-group class="m-2">
        <van-form @submit="onSubmit" class="mb-4" scroll-to-error ref="mainform">
          <span v-for="(item, index) in fields"
            :key="index">
            <van-divider v-if="item.name && item.is_shown"/>
            <span class="van-field__label" v-if="item.name && item.is_shown"><MarkdownRenderer :markdownContent="[item.numbering, item.name].join('. ')" /></span>
            <!-- <span class="van-field__label" v-if="item.name && item.is_shown">{{ [item.numbering, item.name].join('. ') }}</span> -->
            <!-- <MarkdownRenderer :markdownContent="[item.numbering, item.name].join('. ')" /> -->
            <van-field
              label-width="130"
              v-if="item.is_shown"
              v-model="form[item.field_name+ (item.type === 'checkbox' ? '_str' : '')]"
              :placeholder="item.placeholder"
              :type="item.type"
              :maxlength="item.type === 'checkbox' ? 0 : item.limit"
              show-word-limit
              :rules="[{ required: item.is_required, message: 'Kolom ini wajib diisi' }]">
              <template v-if="['select', 'checkbox'].includes(item.type)" #input>
                <van-radio-group @change="is_triggered($event, item, index)" v-model="form[item.field_name]" v-if="item.type == 'select'" :direction="item.direction" class="w-full">
                  <van-radio
                    checked-color="#FB7418"
                    class="mt-2" v-for="(name, index) in item.selection_list" :name="typeof name == 'string' ? name : name.text" :key="index">
                    {{ typeof name == 'string' ? name : name.text }}
                  </van-radio>
                  <van-field
                    :rules="[{ required: item.is_enable_input, message: 'Kolom ini wajib diisi' }]"
                    v-if="item.is_enable_input" placeholder="Silakan masukkan informasi lainnya" type="text" v-model="form[item.field_name + '_manual_input']" ></van-field>
                </van-radio-group>
                <van-checkbox-group :max="item.limit" @change="handlerClickCheckbox($event, item, index)" v-model="form[item.field_name]" v-else-if="item.type == 'checkbox'">
                  <van-checkbox checked-color="#FB7418" shape="square"
                    class="mt-2" v-for="(name, index) in item.selection_list" :name="typeof name == 'string' ? name : name.text" :key="index">
                    {{ typeof name == 'string' ? name : name.text }}</van-checkbox>
                    <van-field
                      :rules="[{ required: item.is_enable_input, message: 'Kolom ini wajib diisi' }]"
                      v-if="item.is_enable_input" placeholder="Silakan masukkan informasi lainnya" type="text" v-model="form[item.field_name + '_manual_input']" ></van-field>
                </van-checkbox-group>
              </template>
            </van-field>
          </span>
          <div class="floating-cta mt-4">
            <van-button :disabled="loading.submit" class="w-full main-cta" type="brand">
              {{ (!campaign.per_page || (total_page == (step + 1))) ? 'Kirim' : 'Selanjutnya' }}
            </van-button>
          </div>
        </van-form>
      </van-cell-group>
    </div>
    <div v-else-if="error_state" align="center">
      <div class="van-empty finish" v-if="code_status === 1004">
        <div class="van-empty__image">
          <img src="@/assets/images/thank-you.svg">
        </div>
        <p class="van-empty__description finish">
          <span class="thankyou-note">Terima kasih!</span>
          Kami mengapresiasi waktu yang telah kamu luangkan untuk isi survey ini. Jika kamu termasuk dalam 100 pelanggan terpilih, Koin VENTENY akan dikirimkan ke akun VENTENY kamu.
        </p>
      </div>
      <van-empty image="error" :description="error_message" v-else/>
    </div>
    <div v-if="page.success_survey" align="center">
      <!-- <van-empty description="Terima kasih! Kami mengapresiasi waktu yang telah kamu luangkan untuk isi survey ini." /> -->
      <div class="van-empty">
        <div class="van-empty__image">
          <img src="@/assets/images/thank-you.svg">
        </div>
        <p class="van-empty__description finish">
          <span class="thankyou-note">Terima kasih!</span>
          Kami mengapresiasi waktu yang telah kamu luangkan untuk isi survey ini. Jika kamu termasuk dalam 100 pelanggan terpilih, Koin VENTENY akan dikirimkan ke akun VENTENY kamu.
        </p>
      </div>
    </div>
    <van-dialog v-model="modal.shown" :title="modal.title" :show-cancel-button="false" confirm-button-text="Oke"
      confirm-button-color="#FB7418" :beforeClose="onCloseModal">
      <template #title>
        <div style="display: flex; align-items: center; justify-content: center; ">
          <van-icon v-if="!modal.is_error" color="#2fb344" name="certificate" size="20" />
          <van-icon v-if="modal.is_error" color="#cd201f" name="close" size="20" />
          <span class="ml-2">{{ modal.title }}</span>
        </div>
      </template>
      <p class="p-4 text-center">{{ modal.message }}</p>
    </van-dialog>
  </div>
</template>
<script>
import { GET_FORM, SUBMIT_FORM } from '../../store/modules/campaigns';
import moment from 'moment';
import MarkdownRenderer from '../components/MarkdownRenderer.vue';

export default {
  metaInfo: {
    title: 'VENTENY',
  },
  components: {
    MarkdownRenderer,
  },
  data() {
    return {
      radio: '',
      banner: [],
      loading: {
        content: true,
        submit: false,
      },
      page: {
        success_survey: false,
      },
      bannerMudik: 'https://nos.wjv-1.neo.id/v-apps/section-contents/photo/f7a8150e-aa3d-414a-87c4-7f5a3f051118.png',
      showPickerDestination: false,
      form: {},
      step: 0,
      total_page: 0,
      destination_list: [
        'Cirebon',
        'Brebes',
        'Semarang',
        'Solo',
        'Jogja',
      ],
      fields: [],
      current_fields: [],
      error_state: false,
      error_message: 'Mohon maaf, program ini sudah berakhir :)',
      modal: {
        title: 'Yay berhasil!',
        shown: false,
        is_error: false,
        message: 'Kamu sudah terdaftar sebagai partisipan mudik bareng GRATIS bareng VENTENY. Kami akan mengirimkan konfirmasi detail melalui WhatsApp ke nomor yang kamu daftarkan. Terima kasih!',
      },
      redirect_url: 'https://venteny.co.id',
      form_type: '',
      detail_product: {},
      description: '',
      campaign: {},
      start_timer: moment().format('YYYY-MM-DD HH:mm:ss'),
      code_status: null,
    };
  },
  async mounted() {
    await this.validateCampaign();
    if (this.$route.query.phone_number) {
      this.$set(this.form, 'phone_number', this.$route.query.phone_number);
    }
  },
  methods: {
    emailValidator(value) {
      const email_regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/g;
      return email_regex.test(value);
    },
    async purchase() {
      const item = this.detail_product;
      const detail_params = [];
      this.fields.forEach((val) => {
        detail_params.push({
          title: val.name,
          value: this.form[val.field_name],
        });
      });
      const obj = {
        detailProduct: {
          id: item.id,
          price: item.price,
          productName: item.name,
          description: '',
          params: detail_params,
          imageProduct: '',
          campaign_id: this.$route.params.id,
          ...this.form,
        },
        imageProduct: '',
        quantity: 1,
        sourceId: item.id,
        sourceType: 'special_program',
        itemName: item.name,
        price: item.price,
        isWithLoading: true,
        isDirectBuy: true,
      };
      console.log(obj);
      window.flutter_inappwebview.callHandler('createOrder', obj);
    },
    async onSubmit() {
      if (this.total_page > 1 && this.step + 1 < this.total_page) {
        this.step += 1;
        const start = this.campaign.per_page * (this.step);
        const end = this.campaign.per_page * (this.step + 1);
        await this.$refs.mainform.resetValidation();
        // this.current_fields = this.fields.filter((v) => v.numbering > start && v.numbering <= end);
        this.fields = this.fields.map((v) => {
          v.is_shown = v.numbering > start && v.numbering <= end;
          return v;
        });
        window.scrollTo(0, 0);
        // await this.$refs.mainform.validate();
        return;
      }
      if (this.form_type === 'transaction') {
        this.purchase();
        return;
      }
      if (this.form_type === 'app_function') {
        console.log('call handler', this.campaign.app_function.function_name, this.campaign.app_function.function_parameter);
        window.flutter_inappwebview.callHandler(this.campaign.app_function.function_name, this.campaign.app_function.function_parameter);
        return;
      }
      const loading_toast = this.$toast.loading({
        message: 'Sending...',
        duration: 0,
      });
      this.loading.submit = true;
      // const obj = cloneDeep(this.form);
      const obj = {};
      /* eslint-disable no-lonely-if */
      this.fields.forEach((val) => {
        if (!val.is_enable_input) {
          if (val.type === 'checkbox') {
            obj[val.field_name] = this.form[val.field_name].join(', ');
          } else {
            obj[val.field_name] = this.form[val.field_name];
          }
        } else {
          if (val.type === 'checkbox' && this.form[`${val.field_name}_manual_input`]) {
            const selected_field = val;
            const text_for_replace = selected_field.selection_list.find((sl) => sl.is_trigger_manual_input === true);
            const index_to_replace = this.form[val.field_name].findIndex((vl) => vl === text_for_replace.text);
            this.$set(this.form[val.field_name], index_to_replace, this.form[`${val.field_name}_manual_input`]);
            obj[val.field_name] = this.form[val.field_name].join(', ');
          } else {
            obj[val.field_name] = this.form[`${val.field_name}_manual_input`];
          }
        }
      });
      obj.time_start = this.start_timer;
      obj.time_end = moment().format('YYYY-MM-DD HH:mm:ss');
      await this.$store.dispatch(SUBMIT_FORM, {
        id: this.$route.params.id,
        data: obj,
        options: {
          params: this.$route.query,
        },
      }).then(() => {
        this.loading.submit = false;
        loading_toast.clear();
        this.modal = {
          title: 'Yay berhasil!',
          shown: true,
          is_error: false,
          message: 'Yeay! Pendaftaran kamu sudah kami terima ya. Kami akan mengirimkan konfirmasi detail melalui WhatsApp ke nomor yang kamu daftarkan. Terima kasih!',
        };
        if (this.campaign.form_type === 'survey') {
          this.modal.message = 'Terima kasih! Kami mengapresiasi waktu yang telah kamu luangkan untuk isi survey ini.';
        }
        Object.keys(this.form, (key) => {
          this.$set(this.form, key, '');
        });
      }).catch(() => {
        this.loading.submit = false;
        loading_toast.clear();
        this.modal = {
          title: 'Oops',
          shown: true,
          is_error: true,
          message: 'Mohon maaf, terjadi kesalahan saat mendaftarkan kamu. Silahkan coba lagi.',
        };
      });
    },
    onCloseModal(action, done) {
      if (!this.modal.is_error) {
        if (this.campaign.form_type === 'survey') {
          this.page.success_survey = true;
          done();
          return;
        }
        window.location.href = this.redirect_url;
      }
      done();
    },
    onConfirm(value) {
      this.form.destination = value;
      this.showPickerDestination = false;
    },
    async validateCampaign() {
      await this.$store.dispatch(GET_FORM, {
        id: this.$route.params.id,
        data: this.$route.query,
      }).then((response) => {
        this.campaign = Object.assign({}, response);
        let current_number = 0;
        this.fields = response.fields.map((v) => {
          if (v.name) {
            v.numbering = current_number + 1;
            current_number += 1;
          } else {
            v.numbering = current_number;
          }
          if (v.selection_list) {
            const sample = v.selection_list[0];
            if (typeof sample === 'object') {
              v.selection_list = v.selection_list.map((v1) => {
                if (v1.is_trigger_manual_input === undefined) {
                  v1.is_trigger_manual_input = false;
                }
                return v1;
              });
            }
          }
          return v;
        });
        this.current_fields = this.fields;
        if (this.campaign.per_page) {
          this.total_page = Math.ceil(this.fields.length / this.campaign.per_page);
          const start = 0;
          const end = this.campaign.per_page;
          // this.current_fields = this.fields.filter((v) => v.numbering > start && v.numbering <= end);
          this.fields = this.fields.map((v) => {
            v.is_shown = v.numbering > start && v.numbering <= end;
            return v;
          });
        }
        this.form = {};
        response.fields.forEach((item) => {
          if (item.type === 'checkbox') {
            this.$set(this.form, item.field_name, []);
            this.$set(this.form, `${item.field_name}_str`, '');
          } else {
            this.$set(this.form, item.field_name, '');
          }
        });
        this.description = response.description;
        this.banner = response.banner_image;
        this.redirect_url = response.redirect_url;
        this.form_type = response.form_type;
        this.detail_product = response.detail_product;
        this.$parent.$data.navbarTitle = response.name;
        this.error_state = false;
      }).catch((err) => {
        console.log(err);
        if (err.response?.data?.error?.code !== 500) {
          this.error_message = err.response.data?.error?.message;
        }
        this.code_status = err.response?.data?.error?.code;
        this.$parent.$data.navbarTitle = 'VENTENY';
        this.error_state = true;
      });
      this.loading.content = false;
    },
    is_triggered(value, item, index) {
      if (!item?.selection_list) {
        return false;
      }
      const f = item.selection_list.find((v) => v.text === value);
      const fIndex = item.selection_list.findIndex((v) => v.text === value);
      if (f?.is_trigger_manual_input) {
        item.selection_list[fIndex].is_enable_input = true;
        this.$set(this.fields[index], 'is_enable_input', true);
      } else {
        this.$set(this.fields[index], 'is_enable_input', false);
      }
      return true;
    },
    handlerClickCheckbox(val, item, index) {
      this.$set(this.form, `${item.field_name}_str`, val.join(', '));
      const value_with_enable_input = item.selection_list.find((v) => v.is_trigger_manual_input === true);
      if (value_with_enable_input?.text && val.includes(value_with_enable_input.text)) {
        this.$set(this.fields[index], 'is_enable_input', true);
      } else {
        this.$set(this.fields[index], 'is_enable_input', false);
      }
    },
  },
};
</script>
<style>
.thankyou-note{
  font-size: 24px;
  font-weight: bold;
  color: #FF8F15;
  text-align: center;
}
.finish{
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: justify;
  align-items: center;
}
.btn-bottom {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
}
.v-form-title {
  margin: 0;
  padding: 32px 16px 16px;
  /* color: rgba(69, 90, 100, 0.6); */
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
}
.floating-cta {
  /* z-index: 10; */
  background-color: #ffffff;
  box-shadow: 0px -4px 4px 0px #E2E2EE;
  /* position: fixed; */
  position: sticky;
  padding: 12px;
  bottom: 0;
  left: 0;
  width: 100%;
}
.main-cta {
  padding: 12px;
  width: 100%;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.37px;
  background-color: #FB7418;
  border-radius: 8px;
  display: block;
  color: #ffffff;
  font-weight: 600;
}
ol {
  list-style: decimal;
}
</style>
