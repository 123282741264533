<template>
  <div v-html="renderedMarkdown"></div>
</template>

<script>
import MarkdownIt from 'markdown-it';

export default {
  props: {
    markdownContent: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      md: new MarkdownIt(),
    };
  },
  computed: {
    renderedMarkdown() {
      return this.md.render(this.markdownContent);
    },
  },
};
</script>

<style scoped>
/* Add any custom styles for your rendered markdown here */
</style>
